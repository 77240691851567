// mainnet
const GLIZZY_ADDRESS = "0xA727ceA448c740fbF827574026395Cf7e5f973c1";
const MUTARD_ADDRESS = "0xe4a7ffC3124a7eFdC027C4472204C8351fbEa27c";
const WEENIE_ADDRESS = "0x9fb96c5cf1e6e9dca9b22210ed9dcea5602c736d";

// rinkeby
// const GLIZZY_ADDRESS = "0x95284001A5B825529cf7aCD614318Ed36730C007";
// const MUTARD_ADDRESS = "0x15958cBA6b5aB71CBD0154D5fD1e83DF0143033b";
// const WEENIE_ADDRESS = "0x08288BF13931e6B03087900AB23a8FcFCEB83B03";

export { GLIZZY_ADDRESS, MUTARD_ADDRESS, WEENIE_ADDRESS };
